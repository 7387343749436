/* 
* Controller om Rijksdriehoeks (RD) coördinaten om te rekenen naar WGS84 Latitude/Longitude systeem
* Interessant is hier de request functie die Rails zelf gebruikt en waarmee je turbo_stream kunt aanroepen.
*/
import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"                         // https://github.com/rails/request.js#how-to-use

export default class extends Controller {
  static targets = [ 'rdx', 'rdy', 'lat', 'lng']
  static values = { toWgs84Url: String, toRdUrl: String }

  connect() {
    // console.log("Hello, GeocoordinatesController!", this.element);
  }

  to_wgs84() {
    const rdx_elm = this.rdxTarget
    const rdy_elm= this.rdyTarget

    if (rdx_elm.value.length==0 || rdx_elm.value==0 || rdy_elm.value.length==0 || rdy_elm.value==0) {
      alert("Voer de coördinaten in en probeer opnieuw.")
    } else {
      // console.log(`RD: ${rdx_elm.value} - ${rdy_elm.value}`);
      const lat_elm= this.latTarget
      const lng_elm= this.lngTarget
      // console.log(`WGS84: ${lat_elm.value} - ${lng_elm.value}`);

      let url= new URL(this.toWgs84UrlValue);
      url.searchParams.append('x', rdx_elm.value);
      url.searchParams.append('y', rdy_elm.value);

      // console.log(url);

      const getRequest= get(url, {
        responseKind: "json",    // andere opties: json, turbo_stream
      })
        .then(response => response.json)
        .then(json => {
          // console.log(json);
          lat_elm.value= json['lat']
          lng_elm.value= json['lng']
        })
    }
  }

  
  to_rd() {
    const lat_elm= this.latTarget
    const lng_elm= this.lngTarget

    if (lat_elm.value.length==0 || lat_elm.value==0 || lng_elm.value.length==0 || lng_elm.value==0) {
      alert("Voer de coördinaten in en probeer opnieuw.")
    } else {
      // console.log(`WGS84: ${lat_elm.value} - ${lng_elm.value}`);

      const rdx_elm = this.rdxTarget
      const rdy_elm= this.rdyTarget
      // console.log(`RD: ${rdx_elm.value} - ${rdy_elm.value}`);

      let url= new URL(this.toRdUrlValue);
      url.searchParams.append('lat', lat_elm.value);
      url.searchParams.append('lng', lng_elm.value);

      // console.log(url);

      /* Verkrijg hier de RD coördinaten */
      const getRequest1= get(url, {
        responseKind: "json",    // andere opties: json, turbo_stream
      })
        .then(response => response.json)
        .then(json => {
          // console.log(json);
          rdx_elm.value= json['x']
          rdy_elm.value= json['y']
          // Terugrekening ivm afronding op hele meters
          lng_elm.value= json['lng']
          lat_elm.value= json['lat']
        })
    }
  }

}
