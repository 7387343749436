/* 
* Deprecated 2022-11. Gebruiken we niet meer omdat dit te omslachtig is. Stel dat je dan ook nog TinyMCE velden zou hebben, moet je die plugins ook weer laden, etc. 
* Maar de code werkt wel. Interessant is hier de request functie die Rails zelf gebruikt en waarmee je turbo_stream kunt aanroepen.
* Ook aardig het Promise voorbeeld met de async functie. 
*/
import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"                         // https://github.com/rails/request.js#how-to-use

export default class extends Controller {

  connect() {
    // console.log("Hello, MillsController!", this.element)
  }

  // Triggered on form submit
  async edit_model_type(event) {
    const model_type_elm= event.target;
    console.log("Laadt deze URL:" + model_type_elm.dataset.editModelTypeUrl);

    // getRequest waarmee je zelfs turbo_stream formats kunt aanroepen
    const getRequest= await get(model_type_elm.dataset.editModelTypeUrl, {
      responseKind: "html",    // andere opties: json, turbo_stream
    })
    .then((response) => response.html)
    .then((html) => {
      // Bouw DOM template met geladen HTML en haal daar alleen de formvelden eruit. En dus niet de gehele FORM
      const template = document.createElement('template');
      template.innerHTML = html.trim();     // Never return a text node of whitespace as the result
      const formElements= template.content.querySelector('#mill_model_type_form_elements');

      // Vervang hier de FORM velden
      const targetElm= document.querySelector('#mill_model_type_form_elements')
      if (targetElm) targetElm.replaceWith(formElements);
    });

  }

}
