import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // console.log("Hello, NestedAttributes!", this.element)
  }

  // Triggered on form submit
  toggle_destroy(event) {
    const form= event.target;
    // console.log("Submitting form ID " + form.id);

    const list= form.querySelectorAll("input[data-toggle-destroy]");
    list.forEach(
      function (currentElm, currentIndex, listObj) {
        // console.log(`Huidige waarde: ${currentElm.value}`)
        if (currentElm.value=='') {
          let inputDestroyElm= null;
          if (currentElm.dataset.toggleDestroy=='next') {
            // Set the next SiblingElement
            inputDestroyElm= currentElm.nextElementSibling;
          } else {
            // Find the specified Element by ID
            inputDestroyElm= document.querySelector(`#${currentElm.dataset.toggleDestroy}`)
          }

          inputDestroyElm.value= true
        }
      }
    );

    // event.preventDefault();
    // event.stopPropagation();

  }

}
