// Swipe Up / Down / Left / Right

const swipeLeftEvt = new CustomEvent("swipeLeft", {
  detail: {
    name: "swipe",
    direction: "left"
  },
});

const swipeRightEvt = new CustomEvent("swipeRight", {
  detail: {
    name: "swipe",
    direction: "right"
  },
});

const swipeDownEvt = new CustomEvent("swipeDown", {
  detail: {
    name: "swipe",
    direction: "down"
  },
});

const swipeUpEvt = new CustomEvent("swipeUp", {
  detail: {
    name: "swipe",
    direction: "up"
  },
});


var initialX = null;
var initialY = null;

export function addSwipeListener(swipe_cont) {
  swipe_cont.addEventListener("touchstart", startTouch, false);
  swipe_cont.addEventListener("touchmove", moveTouch, false);  
}

function startTouch(e) {
  initialX = e.touches[0].clientX;
  initialY = e.touches[0].clientY;
};
 
function moveTouch(e) {
  if (initialX === null) {
    return;
  }
 
  if (initialY === null) {
    return;
  }
 
  var currentX = e.touches[0].clientX;
  var currentY = e.touches[0].clientY;
 
  var diffX = initialX - currentX;
  var diffY = initialY - currentY;
 
  if (Math.abs(diffX) > Math.abs(diffY)) {
    // sliding horizontally
    if (diffX > 0) {
      // console.log("swiped left");
      e.currentTarget.dispatchEvent(swipeLeftEvt);
    } else {
      // console.log("swiped right");
      e.currentTarget.dispatchEvent(swipeRightEvt);
    }  
  } else {
    // sliding vertically
    if (diffY > 0) {
      // console.log("swiped up");
      e.currentTarget.dispatchEvent(swipeUpEvt);
    } else {
      // console.log("swiped down");
      e.currentTarget.dispatchEvent(swipeDownEvt);
    }  
  }

  // Reset 
  initialX = null;
  initialY = null;
};
