//---- Swipe support
import {addSwipeListener} from "./packs/swipe.js"

window.addEventListener("turbo:load", function(event) {

  let swipe_cont;
  if (swipe_cont= document.querySelector('[data-swipe-action]')) {
    addSwipeListener(swipe_cont)

    swipe_cont.addEventListener('swipeLeft', function(evt) { 
      if (evt.target.dataset.swipeLeftTo) {
        window.location= `${evt.target.dataset.swipeLeftTo}`
      } else {
        console.log('swipeLeft: no data-swipe-left-to property available')
      }
      evt.preventDefault();
    });

    swipe_cont.addEventListener('swipeRight', function(evt) { 
      if (evt.target.dataset.swipeRightTo) {
        window.location= `${evt.target.dataset.swipeRightTo}`
      } else {
        console.log('swipeLeft: no data-swipe-right-to property available')
      }
      evt.preventDefault();
    });

  }
});
