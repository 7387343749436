/* 
* Javinto 2022, dropped support for IE11 and jQuery, uses ES6 module now! 
* 
* Import in application.js like: 
* import * as conditionals from "./packs/conditionals.js"
* and then initialize like
* window.addEventListener("turbo:load", () => {conditionals.bind(document)});
*
* then use it outside this file like:
* conditionals.bind(document);
*
* See export statement at the end of this file
*/

/* It's better to do this outside of this module as we then know we have Turbo or not */
// window.addEventListener("turbo:load", () => {bind_conditionals(document)});

function bind_conditionals(html) {
  // General form solution for 'radio fields to hide/show on selected values of other fields'
  html.querySelectorAll('input[type="radio"].show_when').forEach( function(elm) {
    elm.addEventListener('change', (event) => { onChangeRadioShowWhen(event.target) })
  });
  html.querySelectorAll('input[type="radio"]:checked.show_when').forEach( function(item) {onChangeRadioShowWhen(item)});

  // General form solution for 'select fields to hide/show on selected values of other fields'
  html.querySelectorAll('select.show_when').forEach( function(elm) {
    elm.addEventListener('change', (event) => { onChangeSelectShowWhen(event.target) })
  });
  html.querySelectorAll('select.show_when').forEach( function(item) {onChangeSelectShowWhen(item)});

  // General form solution for 'select fields to enable/disable on selected values of other fields'
  html.querySelectorAll('select.disable_when').forEach( function(elm) {
    elm.addEventListener('change', (event) => { onChangeSelectDisableWhen(event.target) })
  });
  html.querySelectorAll('select.disable_when').forEach( function(item) {onChangeSelectDisableWhen(item)});

  // General form solution for 'radio fields to enable/disable other selected fields'
  html.querySelectorAll('input[type="radio"].disable_when').forEach( function(elm) {
    elm.addEventListener('change', (event) => { onChangeRadioDisableWhen(event.target) })
  });
  html.querySelectorAll('input[type="radio"]:checked.disable_when').forEach( function(item) {onChangeRadioDisableWhen(item)});

  // General form solution for 'checkbox fields to hide/show on selected values of other fields'
  html.querySelectorAll('input[type="checkbox"].show_when').forEach( function(elm) {
    elm.addEventListener('change', (event) => { onChangeCheckboxShowWhen(event.target) })
  });
  html.querySelectorAll('input[type="checkbox"].show_when').forEach( function(item) {onChangeCheckboxShowWhen(item)});

  // General form solution for 'checkbox fields to enable/disable on selected values of other fields'
  html.querySelectorAll('input[type="checkbox"].disable_when').forEach( function(elm) {
    elm.addEventListener('change', (event) => { onChangeCheckboxDisableWhen(event.target) })
  });
  html.querySelectorAll('input[type="checkbox"].disable_when').forEach( function(item) {onChangeCheckboxDisableWhen(item)});

  // General form solution for normal 'input fields' to enable/disable on present or blank value
  html.querySelectorAll('input[type="text"].disable_when').forEach( function(elm) {
    elm.addEventListener('change', (event) => { onChangeTextinputDisableWhen(event.target) })
  });
  html.querySelectorAll('input[type="text"].disable_when').forEach( function(item) {onChangeTextinputDisableWhen(item)});

  // General form solution for normal 'input fields' to show/hide on present or blank value
  html.querySelectorAll('input[type="text"].show_when').forEach( function(elm) {
    elm.addEventListener('change', (event) => { onChangeTextinputShowWhen(event.target) })
  });
  html.querySelectorAll('input[type="text"].show_when').forEach( function(item) {onChangeTextinputShowWhen(item)});

}

/* Generieke actie voor show/hide van elementen obv een radio waarde */
function onChangeRadioShowWhen(source) {
  const items= document.querySelectorAll("[data-show-when-name='" +  source.name + "']");

  items.forEach( function(elm) {
    let when_values= elm.dataset.showWhenValues;
    if (when_values!==undefined) {
      when_values= String(when_values);
      if (when_values.length>0 && when_values.split(',').indexOf(source.value)>-1 ) {
        elm.classList.remove('hide');
      } else {
        elm.classList.add('hide');
      }
    }
  })
}

/* Generieke actie voor een enable/disable van elementen obv een select box */
function onChangeRadioDisableWhen(source) {
  const items= document.querySelectorAll("[data-disable-when-name='" +  source.name + "']");

  items.forEach( function(elm) {
    let when_values= elm.dataset.disableWhenValues;
    if (when_values) {
      when_values= String(when_values);
      if (when_values.length>0 && when_values.split(',').indexOf(source.value)>-1 ) {
        elm.setAttribute("disabled", "");
      } else {
        elm.removeAttribute("disabled");
      }
    }
  })
}

/* Generieke actie voor een show/hide van elementen obv een select box */
function onChangeSelectShowWhen(source) {
  const items= document.querySelectorAll("[data-show-when-id='" +  source.id + "']");

  items.forEach( function(elm) {
    let when_values= elm.dataset.showWhenValues
    if (when_values!==undefined) {
      when_values= String(when_values);
      if (when_values.length>0 && when_values.split(',').indexOf(source.value)>-1 ) {
        elm.classList.remove('hide');
      } else {
        elm.classList.add('hide');
      }
    }
  })
}

/* Generieke actie voor een enable/disable van elementen obv een select box */
function onChangeSelectDisableWhen(source) {
  const items= document.querySelectorAll("[data-disable-when-name='" +  source.name + "']");

  items.forEach( function(elm) {
    let when_values= elm.dataset.disableWhenValues;
    if (when_values) {
      when_values= String(when_values);
      if (when_values.length>0 && when_values.split(',').indexOf(source.value)>-1 ) {
        elm.setAttribute("disabled", "");
      } else {
        elm.removeAttribute("disabled");
      }
    }
  })
}

/* Generieke actie voor een show/hide van elementen obv een boolean input veld */
function onChangeCheckboxShowWhen(source) {
  const items= document.querySelectorAll("[data-show-when-id='" +  source.id + "']");

  items.forEach( function(elm) {
    let when_value= elm.dataset.showWhenValues;
    if (when_value!==undefined) {
      if (source.checked==when_value) {
        elm.classList.remove('hide');
      } else {
        elm.classList.add('hide');
      }
    }
  })
}

/* Generieke actie voor een enable/disable van elementen obv een boolean input veld */
function onChangeCheckboxDisableWhen(source) {
  const items= document.querySelectorAll("[data-disable-when-id='" +  source.id + "']");

  items.forEach( function(elm) {
    let when_value= elm.dataset.disableWhenValues;
    if (source.checked==when_value) {
      elm.disabled=true;
    } else {
      elm.disabled=false;
    }
  })
}

/* Generieke actie voor een enable/disable van elementen obv een normaal tekst input veld.
 * data-disable-when-value='<value>' waar value: 'blank', 'present' of een specifieke waarde
 */
function onChangeTextinputDisableWhen(source) {
  const items= document.querySelectorAll("[data-disable-when-id='" +  source.id + "']");

  items.forEach( function(elm) {
    let when_value= elm.dataset.disableWhenValue;

    switch(when_value) {
      case 'blank':
        elm.disabled= (source.value=='');
        break;
      case 'present':
        elm.disabled= (source.value!=='');
        break;
      default:
        elm.disabled= (source.value==when_value);
      }
  })
}

/* Generieke actie voor een show/hide van elementen obv een normaal tekst input veld.
 * data-show-when-value='<value>' waar value: 'blank', 'present' of een specifieke waarde
 */
function onChangeTextinputShowWhen(source) {
  const items= document.querySelectorAll("[data-show-when-id='" +  source.id + "']");

  items.forEach( function(elm) {
    let when_value= elm.dataset.showWhenValue;

    switch(when_value) {
      case 'blank':
        if (source.value=='') {elm.classList.remove('hide')} else {elm.classList.add('hide')}
        break;
      case 'present':
        if (source.value!=='') {elm.classList.remove('hide')} else {elm.classList.add('hide')}
        break;
      default:
        elm.classList.remove('hide');
      }
  })
}

export {bind_conditionals as bind}