import { Controller } from "@hotwired/stimulus"
import * as conditionals from "../packs/conditionals.js"

export default class extends Controller {

  connect() {
    // console.log("Hello, ConditionalForms!", this.element);
    conditionals.bind(document);
  }
}
